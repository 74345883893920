import noImage from "../Assets/Images/no-image.jpg";

export const imageBaseUrl = 'https://dygo8x5k8m6hk.cloudfront.net/';

export const apiUrls = {
    login: "/authAdmin/login",
    sendOtp: "/authAdmin/sendOtp",
    verifyOtp: "/authAdmin/verifyOtp",
    resetPassword: "/authAdmin/resetPassword",
    admins: "/admin/admins",
    states: "/states",
    addAdmins: "/admin/addAdmin",
    activatedAccount: "/account/activate/",
    suspendAccount: "/account/suspend/",
    deleteAccount: "/account/delete/",
    getUsers: "/admin/users/",
    getUserDetail: "/admin/users/detail/",
    updateUserDetail: "/admin/users/update/",
    getClientDetail: "/admin/client/detail/",
    getAllProperty: "/admin/client/property/",
    getAllOrders: "/admin/client/orders/",
    getClients: "/admin/client/",
    getUserCount: "admin/users/count/",
    getAnalyticsCount: "admin/users/analytics/",
    getOrdersHistory: "admin/orders/order/",
    modifyOrder: "admin/orders/modifyOrder/",
    getRevisionHistory: "admin/orders/revisions/",
    getTopUsers: "/admin/users/topUsers",
    getTopClients: "/admin/client/topClients",
    getChatList: "/admin/message/chatList",
    getChatListHistory: "/admin/message/history/",
    sendMessage: "/admin/message/chat/",
    getSupportTokenList: "/admin/support",
    getTokenDetail: "/support/",
    supportTokenMessage: "/admin/message/token/",
    getUnapprovedUsers: "/admin/users/unApprovedUsers",
    getAdminDetail: "/admin/detail/",
    updateAdminDetails: "/admin/update/",
    getAssignedOrders: "admin/users/assignedOrders/",
    getCompletedOrders: "admin/users/completedOrders/",
    getRevisionOrders: "admin/users/revisionOrders/",
    getCancelledOrders: "admin/users/cancelledOrders/",
};

const getCapitalizedFirstLetter = (str) => {
    if (typeof str !== 'string' || str.length === 0) {
        return '';
    }
    return str.charAt(0).toUpperCase();
};

export const getInitials = (firstName, lastName) => {
    const firstInitial = getCapitalizedFirstLetter(firstName);
    const lastInitial = getCapitalizedFirstLetter(lastName);

    return `${firstInitial}${lastInitial}`;
};

export const getImageUrl = (image) => {

    return image ? `${imageBaseUrl}${image}` : noImage;
};

export const getStatus = (status) => {
    var data = {
        text: '',
        color: ''
    };
    switch (status) {
        case 2:
            data.text = "Active"
            data.color = "#35C736"
            break;

        case 3:
            data.text = "Suspended"
            data.color = "#F3AF35"
            break;

        case 1:
            data.text = "Hold"
            data.color = "#F3AF35"
            break;

        default:
            break;
    }

    return data;
};