import axios from "../Utils/axios";
import { accountConstants } from "./constants";
import { apiUrls } from "../Helpers/Constants";

export const suspendAccount = (params, id) => {
    return async (dispatch) => {
        dispatch({ type: accountConstants.SUSPEND_ACCOUNT_REQUEST });

        try {
            const res = await axios.post(`${apiUrls.suspendAccount}${id}`, params);

            if (res.status === 200) {
                dispatch({
                    type: accountConstants.SUSPEND_ACCOUNT_SUCCESS,
                    payload: {
                        admin: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: accountConstants.SUSPEND_ACCOUNT_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch admins'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching admins:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

// Assuming your getAdmins action creator is defined like this
export const deleteAccount = (params, id) => {
    return async (dispatch) => {
        dispatch({ type: accountConstants.DELETE_ACCOUNT_REQUEST });

        try {
            const res = await axios.post(`${apiUrls.deleteAccount}${id}`, params);

            if (res.status === 200) {
                dispatch({
                    type: accountConstants.DELETE_ACCOUNT_SUCCESS,
                    payload: {
                        admin: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: accountConstants.DELETE_ACCOUNT_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch admins'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching admins:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};


export const activateAccount = (id) => {
    return async (dispatch) => {
        dispatch({ type: accountConstants.ACTIVATE_ACCOUNT_REQUEST });

        try {
            const res = await axios.post(`${apiUrls.activatedAccount}${id}`);

            if (res.status === 200) {
                dispatch({
                    type: accountConstants.ACTIVATE_ACCOUNT_SUCCESS,
                    payload: {
                        admin: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: accountConstants.ACTIVATE_ACCOUNT_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch admins'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching admins:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

