import React from "react";
import PropTypes from "prop-types";
//Icons importing from /Assets/Icons
import MailNegative from "../Assets/Icons/Huge-icon-communication-outline-mail-negative.svg";
import Shield from "../Assets/Icons/Huge-icon-ecommerce-outline-shield.svg";
import OutlineBook from "../Assets/Icons/huge-icon-education-outline-book.svg";
import OutlineStack from "../Assets/Icons/huge-icon-education-outline-cube-stack.svg";
import OutlineUser from "../Assets/Icons/Huge-icon-user-outline-user.svg";
import MenuUserOutline from "../Assets/Icons/Huge-icon-menu-outline-menu-user.svg";
import SmartHomeOutline from "../Assets/Icons/Huge-icon-smart-house-outline-home-shield.svg";
import FolderOutline from "../Assets/Icons/Huge-icon-files-and-folder-outline-archive-upload.svg";
import HomeGraph from "../Assets/Icons/Huge-icon-smart-house-outline-home-graph-01.svg";
import ClockCircle from "../Assets/Icons/Huge-icon-interface-outline-clock-circle.svg";
import RoundedDollar from "../Assets/Icons/dollar-rounded.svg";
import BagOutline from "../Assets/Icons/Cart.svg";
import HomeRefresh from "../Assets/Icons/Group-78817.svg";
import HomeVector from "../Assets/Icons/Vector-1.svg";
import HomeChecked from "../Assets/Icons/Vector.svg";
import HomeLocation from "../Assets/Icons/Huge-icon-smart-house-solid-home-location-01.svg";
import HomeNotify from "../Assets/Icons/Huge-icon-smart-house-outline-home-notification01.svg";
import ArrowUp from "../Assets/Icons/Huge-icon-smart-house-outline-home-arrow-up1.svg";
import OutlineSearch from "../Assets/Icons/Huge-icon-interface-outline-search.svg";
import Message from "../Assets/Icons/Message.svg";
import Bell_Icon from "../Assets/Icons/Bell_Icon.svg";
import Diamond from "../Assets/Icons/diamond.svg";
import CoinDollar from "../Assets/Icons/coin_dollar.svg";
import WalletOutline from "../Assets/Icons/WalletOutline.svg";
import LockOutline from "../Assets/Icons/LockOutline.svg";
import LifebuoyOutline from "../Assets/Icons/LifebuoyOutline.svg";
import UserPolygon from "../Assets/Icons/UserPolygon.svg";
import OutlineLogout from "../Assets/Icons/Huge-icon-interface-outline-logout.svg";
import OutlineGrid from "../Assets/Icons/Huge-icon-grid-outline-grid.svg";
import GrayOutlineGrid from "../Assets/Icons/Huge-icon-grid-outline-grid-G.svg";
import OutlineLayout from "../Assets/Icons/Huge-icon-grid-outline-layout.svg";
import WhiteOutlineLayout from "../Assets/Icons/Huge-icon-grid-outline-layout-W.svg";
import OutlineSort from "../Assets/Icons/Huge-icon-interface-outline-sort-rectangle.svg";
import OutlineFilter from "../Assets/Icons/Huge-icon-interface-outline-filter.svg";
import Calendar from "../Assets/Icons/Calendar.svg";
import Home from "../Assets/Icons/home.svg";
import HomeSelected from "../Assets/Icons/home_selected.svg";
import UserManagement from "../Assets/Icons/user_managemnet.svg";
import UserManagementSelected from "../Assets/Icons/user_management_selected.svg";
import Accounting from "../Assets/Icons/accounting.svg";
import AccountingSelected from "../Assets/Icons/accounting_selected.svg";
import MessageSelected from "../Assets/Icons/message_selected.svg";
import DownArrow from "../Assets/Icons/down_arrow.svg";
import RightArrow from "../Assets/Icons/right_arrow.svg";
import User from "../Assets/Icons/user.svg";
import UserSelected from "../Assets/Icons/user_selected.svg";
import File from "../Assets/Icons/file.svg";
import FileSelected from "../Assets/Icons/file_selected.svg";
import Inspector from "../Assets/Icons/inspector.svg";
import InspectorSelected from "../Assets/Icons/inspector_selected.svg";
import Reviewer from "../Assets/Icons/reviewer.svg";
import ReviewerSelected from "../Assets/Icons/reviewer_selected.svg";
import More from "../Assets/Icons/more-horizontal.svg";
import AddUser from "../Assets/Icons/Group 79755.svg";
import TotalUser from "../Assets/Icons/totalUser.svg";
import ThisMonthUser from "../Assets/Icons/Group 79112.svg";
import ActiveUser from "../Assets/Icons/Group 79113.svg";
import TotalAppraisers from "../Assets/Icons/Group 79111.svg";
import ThisMonthAppraisers from "../Assets/Icons/thismonthaa.svg";
import ActiveAppraisers from "../Assets/Icons/activeAppraiser.svg";
import TotalInspector from "../Assets/Icons/totalInspector.svg";
import ThisMonthInspector from "../Assets/Icons/monthInspector.svg";
import ActiveInspector from "../Assets/Icons/activeInspector.svg";
import TotalReviewer from "../Assets/Icons/totalReviewer.svg";
import ThisMonthReviewer from "../Assets/Icons/monthReviewer.svg";
import ActiveReviewer from "../Assets/Icons/activeReview.svg";
import TotalOrder from "../Assets/Icons/totalOrder.svg";
import ChatSupport from "../Assets/Icons/quiz.svg";
import Substract from "../Assets/Icons/Subtract.svg";
import OpenTicket from "../Assets/Icons/Vector (1).svg";
import ClosedTicket from "../Assets/Icons/Vector (2).svg";
import SubstractSelected from "../Assets/Icons/Vector (3).svg";
import OpenTicketSelected from "../Assets/Icons/file (1).svg";
import ClosedTicketSelected from "../Assets/Icons/file remove.svg";
import Cancel from "../Assets/Icons/cancel.svg";
import Order from "../Assets/Icons/order.svg";
import Invoice from "../Assets/Icons/invoice.svg";
import Calender from "../Assets/Icons/calender.svg";
import Edit from "../Assets/Icons/edit.svg";
import Property from "../Assets/Icons/home location.svg";
import Orderss from "../Assets/Icons/orders.svg";
import PropertyNotSelected from "../Assets/Icons/propery not selected.svg";
import OrderSelected from "../Assets/Icons/Union.svg";
import ContactInfo from "../Assets/Icons/contact number.svg";
import Email from "../Assets/Icons/email address.svg";
import Grid1 from "../Assets/Icons/grid1.svg";
import Grid2 from "../Assets/Icons/grid2.svg";
import Grid3 from "../Assets/Icons/grid3.svg";
import Grid4 from "../Assets/Icons/grid4.svg";
import Grid5 from "../Assets/Icons/grid5.svg";
import Attachment from "../Assets/Icons/Huge-icon-files and folder-outline-attachment.svg";
import Modify from "../Assets/Icons/modify.svg";
import Assigned from "../Assets/Icons/assigned.svg";
import AssignedSelected from "../Assets/Icons//assigned-selected.svg";
import Completed from "../Assets/Icons/completed.svg";
import CompletedSelected from "../Assets/Icons/completed-selected.svg";
import Revision from "../Assets/Icons/revision.svg";
import RevisionSelected from "../Assets/Icons/revision-selected.svg";
import Hold from "../Assets/Icons/hold.svg";
import HoldSelected from "../Assets/Icons/hold-selected.svg";
import Schedule from "../Assets/Icons/schedule.svg";
import ScheduleSelected from "../Assets/Icons/schedule-selected.svg";
import Pending from "../Assets/Icons/pending.svg";
import PendingSelected from "../Assets/Icons/pending-selected.svg";

const icons = {
  MailNegative,
  Shield,
  OutlineBook,
  OutlineStack,
  OutlineUser,
  MenuUserOutline,
  SmartHomeOutline,
  FolderOutline,
  HomeGraph,
  BagOutline,
  RoundedDollar,
  ClockCircle,
  HomeRefresh,
  HomeVector,
  HomeChecked,
  HomeLocation,
  HomeNotify,
  ArrowUp,
  OutlineSearch,
  Bell_Icon,
  Message,
  CoinDollar,
  Diamond,
  WalletOutline,
  LockOutline,
  LifebuoyOutline,
  UserPolygon,
  OutlineLogout,
  OutlineGrid,
  OutlineLayout,
  OutlineSort,
  WhiteOutlineLayout,
  OutlineFilter,
  GrayOutlineGrid,
  Calendar,
  Home,
  HomeSelected,
  UserManagement,
  UserManagementSelected,
  Accounting,
  AccountingSelected,
  MessageSelected,
  DownArrow,
  RightArrow,
  User,
  UserSelected,
  File,
  FileSelected,
  Inspector,
  InspectorSelected,
  Reviewer,
  ReviewerSelected,
  More,
  AddUser,
  TotalUser,
  ThisMonthUser,
  ActiveUser,
  TotalAppraisers,
  ThisMonthAppraisers,
  ActiveAppraisers,
  TotalInspector,
  TotalReviewer,
  ThisMonthInspector,
  ThisMonthReviewer,
  ActiveInspector,
  ActiveReviewer,
  TotalOrder,
  ChatSupport,
  Substract,
  OpenTicket,
  ClosedTicket,
  SubstractSelected,
  OpenTicketSelected,
  ClosedTicketSelected,
  Cancel,
  Order,
  Invoice,
  Calender,
  Edit,
  Property,
  Orderss,
  PropertyNotSelected,
  OrderSelected,
  ContactInfo,
  Email,
  Grid1,
  Grid2,
  Grid3,
  Grid4,
  Grid5,
  Attachment,
  Modify,
  Assigned,
  AssignedSelected,
  Completed,
  CompletedSelected,
  Revision,
  RevisionSelected,
  Hold,
  HoldSelected,
  Schedule,
  ScheduleSelected,
  Pending,
  PendingSelected
};

const Icon = ({ name, ...props }) => {
  const IconComponent = icons[name];

  if (!IconComponent) {
    return null;
  }

  return <img src={IconComponent} alt={name} {...props} />;
};

Icon.propTypes = {
  name: PropTypes.oneOf([
    "MailNegative",
    "Shield",
    "OutlineBook",
    "OutlineStack",
    "OutlineUser",
    "MenuUserOutline",
    "SmartHomeOutline",
    "FolderOutline",
    "HomeGraph",
    "BagOutline",
    "RoundedDollar",
    "ClockCircle",
    "HomeRefresh",
    "HomeVector",
    "HomeChecked",
    "HomeLocation",
    "HomeNotify",
    "ArrowUp",
    "OutlineSearch",
    "Bell_Icon",
    "Message",
    "CoinDollar",
    "Diamond",
    "WalletOutline",
    "LockOutline",
    "LifebuoyOutline",
    "UserPolygon",
    "OutlineLogout",
    "OutlineGrid",
    "OutlineLayout",
    "OutlineSort",
    "OutlineFilter",
    "Calendar",
    "Home",
    "HomeSelected",
    "UserManagement",
    "UserManagementSelected",
    "AccountingSelected",
    "Accounting",
    "MessageSelected",
    "DownArrow",
    "RightArrow",
    "User",
    "UserSelected",
    "File",
    "FileSelected",
    "Inspector",
    "InspectorSelected",
    "Reviewer",
    "ReviewerSelected",
    "More",
    "AddUser",
    "TotalUser",
    "ThisMonthUser",
    "ActiveUser",
    "TotalAppraisers",
    "ThisMonthAppraisers",
    "ActiveAppraisers",
    "TotalInspector",
    "TotalReviewer",
    "ThisMonthInspector",
    "ThisMonthReviewer",
    "ActiveInspector",
    "ActiveReviewer",
    "TotalOrder",
    "ChatSupport",
    "Substract",
    "OpenTicket",
    "ClosedTicket",
    "SubstractSelected",
    "OpenTicketSelected",
    "ClosedTicketSelected",
    "Cancel",
    "Order",
    "Invoice",
    "Calender",
    "Edit",
    "Property",
    "Orderss",
    "PropertyNotSelected",
    "OrderSelected",
    "ContactInfo",
    "Email",
    "Grid1",
    "Grid2",
    "Grid3",
    "Grid4",
    "Grid5",
    "Attachment",
    "Modify",
    "Assigned",
    "AssignedSelected",
    "Completed",
    "CompletedSelected",
    "Revision",
    "RevisionSelected",
    "Hold",
    "HoldSelected",
    "Schedule",
    "ScheduleSelected",
    "Pending",
    "PendingSelected",  
  ]).isRequired,
};

export default Icon;
