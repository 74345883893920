import * as React from 'react';
import { Box } from '@mui/material';
import Icon from "../Helpers/Icon";

const AnalyticsCard = ({ isShow, icon, number, text }) => {

    return (
        <Box sx={{
            display: 'grid',
            alignItems: 'center',
            justifyContent: 'center',
            height: "246px",
            width: "270px",
            background: "#F2F2F2 0% 0% no-repeat padding-box",
            borderRadius: "24px",
            marginRight: "20px"
        }}>
            <Icon
                name={icon}
                style={{
                    marginTop: "4px",
                    width: "73px",
                    height: "73px",
                    margin: "auto"
                }}
            />

            <span style={{
                color: "#000000",
                fontSize: "34px",
                font: "normal normal bold 34px/56px Poppins",
                marginTop: "-29px",
                textAlign: "center",
            }}>{number}</span>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: "-4em"
            }}>
                <span style={{
                    color: "#000000",
                    fontSize: "15px",
                    font: "normal normal normal 15px/25px Poppins",
                }}>{text}</span>
                {isShow && (
                    <span style={{
                        color: "#000000",
                        fontSize: "10px",
                        marginLeft: "5px",
                        font: "normal normal normal 10px/16px Poppins",
                    }}>This Month</span>
                )}
            </Box>
        </Box>
    );
}

export default AnalyticsCard;