import axios from "axios";
import Store from './Store';

const api = "https://dev-ndd-api.flynautstaging.com/api/"
// const api = "http://localhost:5008/api";

const token = localStorage.getItem("token");

const axiosInstance = axios.create({
  baseURL: api,
  headers: {
    Authorization: token ? `Bearer ${token}` : "",
  },
});

axiosInstance.interceptors.request.use((req) => {
  const { auth } = Store.getState();
  if (auth.token) {
    req.headers.Authorization = `Bearer ${auth.token}`;
  }
  return req;
});

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    const status = error.response ? error.response.status : 500;
    if (status && status === 500) {
      console.log({
        message: "There was a problem with the server. Please try again later.",
        status: status,
        errorType: "Red",
      });
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
