import { Box, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import Template from "../../Template";
import Ellipse8 from "../../Assets/Images/Ellipse8.svg";
import { clientHeader, appraiserHeader, inspectorHeader, reviewerHeader } from '../../Helpers/DataHelper';
import Icon from "../../Helpers/Icon";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail, getClientDetail, getAllPropertyByClientId, getAllOrdersByClientId } from "../../Actions/users";
import { getAssignedOrders, getCancelledOrders, getCompletedOrders, getRevisionOrders } from "../../Actions/orders";
import { modifyOrderFee } from "../../Actions/orders";
import AlertBox from "../../Components/Alert";
import Button from "../../Components/Core/Button";
import { useLocation } from "react-router";
import { useNavigate } from 'react-router-dom';
import { getInitials, getStatus } from '../../Helpers/Constants';
import PropertyCard from "../../Components/PropertyCard";
import { modifyOrderSchema } from "../../Validations";
const moment = require('moment');

const UserDetail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { type, id } = location.state;
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [pageType, setPageType] = useState("Property");
    const [data, setData] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [header, setHeader] = useState([]);
    const [errors, setErrors] = useState({});
    const [alert, setAlert] = useState({ show: false, type: "", message: "" });
    const [modifyOrder, setModifyOrder] = useState({
        fee: '',
        date: '',
        note: '',
    });

    useEffect(() => {
        setHeaders(type);
        getUserDetailData();
    }, []);

    const setHeaders = (value) => {
        switch (value) {
            case 'Client':
                setHeader(clientHeader)
                setPageType(clientHeader[0].value)
                getAllGridData(clientHeader[0].value);
                break;

            case 'Appraiser':
                setHeader(appraiserHeader)
                setPageType(appraiserHeader[0].value)
                getAllGridData(appraiserHeader[0].value);
                break;

            case 'Inspector':
                setHeader(inspectorHeader)
                setPageType(inspectorHeader[0].value)
                getAllGridData(inspectorHeader[0].value);
                break;

            case 'Reviewer':
                setHeader(reviewerHeader)
                setPageType(reviewerHeader[0].value)
                getAllGridData(reviewerHeader[0].value);
                break;

            default:
                break;
        }
    };


    const handleChange = (field, value) => {
        setModifyOrder(prevData => {
            return {
                ...prevData,
                [field]: value,
            };
        });
    };


    const getUserDetailData = async () => {
        setIsLoading(true);
        setData([])

        try {
            const result = await dispatch(type === 'Client' ? getClientDetail(id) : getUserDetail(id));
            if (result && result.data) {
                type === 'Client' ? setData(result.data) : setData(result.data[0])
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error occurred while fetching user data:", error);

            setIsLoading(false);
            setAlert({ show: true, type: 'error', message: error?.response?.data?.error || "An error occurred." });
        }
    };

    const getAllGridData = async (type) => {
        setIsLoading2(true);
        setPageType(type)
        setGridData([])

        try {
            const result = await getApiLink(type);
            setIsLoading2(false);
            if (result && result.data) {
                setGridData(result.data)
            }

        } catch (error) {
            console.error("Error occurred while fetching user data:", error);

            setIsLoading2(false);
            setAlert({ show: true, type: 'error', message: error?.response?.data?.error || "An error occurred." });
        }
    };

    const onSubmit = async (id) => {
        try {
            await modifyOrderSchema.validate(modifyOrder, {
                abortEarly: false,
            });

            setIsLoading2(true);

            const body = {
                ...modifyOrder,
                date: moment(modifyOrder.date)
            }

            setIsLoading2(false);

            dispatch(modifyOrderFee(body, id))
                .then((result) => {
                    setIsLoading(false);
                    setAlert({ show: true, type: 'success', message: "New Admin added successfully" });
                    getAllGridData(pageType)
                    setModifyOrder({
                        fee: '',
                        date: '',
                        note: '',
                    })
                })
                .catch((err) => {
                    setIsLoading(false);
                    setAlert({ show: true, type: 'error', message: err?.response.data?.error });
                });
        } catch (validationError) {
            const formattedErrors = {};
            if (validationError && validationError.inner) {
                validationError.inner.forEach((error) => {
                    formattedErrors[error.path] = error.message;
                });
            }

            setErrors(formattedErrors);
            setIsLoading(false);
        }
    };

    const getApiLink = async (type) => {
        if (type === 'Property') {
            return dispatch(getAllPropertyByClientId(id));
        } else if (type === 'Orders') {
            return dispatch(getAllOrdersByClientId(id))
        } else if (type === 'Assigned') {
            return dispatch(getAssignedOrders(id))
        } else if (type === 'Completed') {
            return dispatch(getCompletedOrders(id))
        } else if (type === 'Revision') {
            return dispatch(getRevisionOrders(id))
        } else if (type === 'Hold') {
            return dispatch(getCancelledOrders(id))
        }
    }

    const goBack = () => {
        navigate('/user-management');
    }

    const onEditClick = async () => {
        navigate('/edit-profile', { state: { type: type, id: id } });
    }

    return (
        <Template
            showHeader
            showSidebar
            headerProps={{
                showSearch: true,
                showNotification: true,
                showProfile: true
            }}
            sidebarProps={{
                page: "User Management"
            }}
     
            containerStyle={{
                backgroundImage: `url(${Ellipse8})`,
            }}
        >
            <AlertBox open={alert.show} type={alert.type} message={alert.message} onChange={(value) => { setAlert({ show: value, type: '', message: "" }); }} />

            <Box
                sx={{
                    border: "1px solid #FFFFFF",
                    background:
                        "transparent linear-gradient(119deg, #FFFFFF66 0%, #FFFFFF24 100%) 0% 0% no-repeat padding-box",
                    boxShadow: "15px 18px 50px #00000029",
                    borderRadius: "24px",
                    backgroundColor: "#F2F2F2",
                    opacity: "1",
                    padding: "1.5em",
                    backdropFilter: "blur(50px)",
                    WebkitBackdropFilter: "blur(50px)",
                    width: "73%",
                    margin: "auto",
                    marginLeft: "20em"
                }}
            >
                {isLoading ? (
                    <CircularProgress
                        size={40}
                        sx={{
                            color: "#2C80FF",
                            marginRight: "6px",
                            display: "flex",
                            margin: "Auto"
                        }}
                    />
                ) : (
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: "-4px",
                                marginBottom: "40px",
                                justifyContent: "space-between"
                            }}
                        >
                            <div style={{ display: 'grid' }}>
                                <span className="allTransaction" style={{ font: 'normal normal 600 19px/31px Poppins' }}>{type} Detail</span>
                                <span style={{ font: 'normal normal medium 12px/19px Poppins', fontSize: '12px', color: "black", opacity: 0.5 }}>Lorem Ipsum is simply dummy text of the printing and type setting industry.</span>
                            </div>
                            <div style={{ display: "flex" }}>
                                <Button
                                    onClick={onEditClick}
                                    icon="Edit"
                                    style={{
                                        width: "12em",
                                        marginTop: "auto",
                                        marginBottom: "14px",
                                        marginLeft: "15px",
                                        height: "40px",
                                    }}
                                >
                                    Edit {type}
                                </Button>

                                <Button
                                    onClick={goBack}
                                    style={{
                                        width: "114px",
                                        marginTop: "auto",
                                        marginBottom: "14px",
                                        marginLeft: "15px",
                                        height: "40px",
                                    }}
                                >
                                    Back
                                </Button>
                            </div>
                        </Box>

                        <Box
                            sx={{
                                // background: "#F8F8F8 0% 0% no-repeat padding-box",
                                borderRadius: "16px",
                                background: "rgba(239,238,238,1)",
                                opacity: 1,
                                padding: "1.5em",
                                mt: "1em",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginTop: "-4px",
                                    marginBottom: "40px",
                                    justifyContent: "space-between"
                                }}
                            >
                                <div style={{ display: "flex", }}>
                                    <Box
                                        sx={{
                                            background: "#deecf4 0% 0% no-repeat padding-box",
                                            borderRadius: "50%",
                                            height: "104px",
                                            mt: "-2px",
                                            width: "104px",
                                            display: "flex",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                            alignItems: "center",
                                            marginRight: "25px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                font: " normal normal 600 35px/35px Poppins",
                                                color: "#2593D6",
                                                fontSize: "24px",
                                                marginTop: "6px",
                                            }}
                                        >
                                            {getInitials(data?.firstName, data?.lastName)}
                                        </span>
                                    </Box>

                                    <div style={{ display: 'grid' }}>
                                        <div style={{ display: 'flex' }}>
                                            <span style={{ font: 'normal normal 600 18px/29px Poppins', fontSize: '18px', color: "black", }}>{data?.firstName} {data?.lastName}</span>
                                            <span
                                                style={{
                                                    font: " normal normal 600 15px/23px Poppins",
                                                    color: getStatus(data.status)?.color,
                                                    fontSize: "11px",
                                                    marginLeft: '10px',
                                                    marginTop: '5px',
                                                }}
                                            >
                                                {getStatus(data.status)?.text}
                                            </span>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <Icon
                                                name="ContactInfo"
                                                style={{
                                                    width: "17px",
                                                    height: "17px",
                                                    marginRight: "15px"
                                                }}
                                            />
                                            <span style={{ font: 'normal normal normal 12px/19px Poppins', fontSize: '12px', color: "black", }}>{data?.cellPhone ? data.cellPhone : "N/A"}</span>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <Icon
                                                name="Email"
                                                style={{
                                                    width: "17px",
                                                    height: "17px",
                                                    marginRight: "15px"
                                                }}
                                            />
                                            <span style={{ font: 'normal normal normal 12px/19px Poppins', fontSize: '12px', color: "black", }}>{data?.email}</span>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: 'flex' }}>
                                    {type === 'Client' ? (
                                        <>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                                                    borderRadius: "17px",
                                                    height: '67px',
                                                    padding: '11px',
                                                    marginRight: '15px'
                                                }}
                                            >
                                                <Icon
                                                    name="TotalReviewer"
                                                    style={{
                                                        width: "60px",
                                                        height: "60px",
                                                        marginRight: "15px"
                                                    }}
                                                />
                                                <div style={{ display: 'grid', marginRight: '20px' }}>
                                                    <span style={{ font: 'normal normal 600 19px/31px Poppins', fontSize: '19px', color: "black" }}>{data?.propertyData ? data?.propertyData : 0}</span>
                                                    <span style={{ font: 'normal normal normal 13px/21px Poppins', fontSize: '13px', color: "black" }}>Total Properties</span>
                                                </div>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                                                    borderRadius: "17px",
                                                    height: '67px',
                                                    padding: '11px',
                                                    marginRight: '15px'
                                                }}
                                            >
                                                <Icon
                                                    name="TotalOrder"
                                                    style={{
                                                        width: "60px",
                                                        height: "60px",
                                                        marginRight: "15px"
                                                    }}
                                                />
                                                <div style={{ display: 'grid', marginRight: '20px' }}>
                                                    <span style={{ font: 'normal normal 600 19px/31px Poppins', fontSize: '19px', color: "black" }}>{data?.orderCount ? data?.orderCount : 0}</span>
                                                    <span style={{ font: 'normal normal normal 13px/21px Poppins', fontSize: '13px', color: "black" }}>Total Orders</span>
                                                </div>
                                            </Box>
                                        </>
                                    ) : (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                background: "#FFFFFF 0% 0% no-repeat padding-box",
                                                borderRadius: "17px",
                                                height: '67px',
                                                padding: '11px',
                                                marginRight: '15px'
                                            }}
                                        >
                                            <Icon
                                                name="TotalAppraisers"
                                                style={{
                                                    width: "60px",
                                                    height: "60px",
                                                    marginRight: "15px"
                                                }}
                                            />
                                            <div style={{ display: 'grid', marginRight: '20px' }}>
                                                <span style={{ font: 'normal normal 600 19px/31px Poppins', fontSize: '19px', color: "black" }}>{data?.completedOrders ? data?.completedOrders : 0}</span>
                                                <span style={{ font: 'normal normal normal 13px/21px Poppins', fontSize: '13px', color: "black" }}>Total {type} Completed</span>
                                            </div>
                                        </Box>
                                    )}
                                </div>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginTop: "-4px",
                                    marginBottom: "40px",
                                    justifyContent: "flex-start",
                                    flexWrap: "wrap",
                                    width: "100%",
                                }}
                            >
                                {type !== 'Client' && (
                                    <>
                                        <div style={{ display: 'grid', marginRight: '20px', marginTop: '25px' }}>
                                            <span style={{ font: 'normal normal 400 14px/14px Poppins', fontSize: '14px', color: "black" }}>License Number</span>
                                            {type.stateLicenses && type.stateLicenses.length && (
                                                <>
                                                    {
                                                        type.stateLicenses.map((item, index) => (
                                                            <span key={index}
                                                                style={{
                                                                    font: 'normal normal 600 15px/25px Poppins', fontSize: '15px', color: "black", marginTop: '13px'
                                                                }}
                                                            >
                                                                {item.licenseNumber},
                                                            </span>
                                                        ))
                                                    }
                                                </>
                                            )}
                                            <span style={{ font: 'normal normal 600 15px/25px Poppins', fontSize: '15px', color: "black", marginTop: '13px' }}>{data?.address ? data.address : "N/A"}</span>
                                        </div>

                                        <div style={{ display: 'grid', marginRight: '20px', marginTop: '25px' }}>
                                            <span style={{ font: 'normal normal 400 14px/14px Poppins', fontSize: '14px', color: "black" }}>Experience</span>
                                            <span style={{ font: 'normal normal 600 15px/25px Poppins', fontSize: '15px', color: "black", marginTop: '13px' }}>{data?.experience ? data.experience : 0}</span>
                                        </div>

                                        <div style={{ display: 'grid', marginRight: '20px', marginTop: '25px' }}>
                                            <span style={{ font: 'normal normal 400 14px/14px Poppins', fontSize: '14px', color: "black" }}>E & O Company Name</span>
                                            <span style={{ font: 'normal normal 600 15px/25px Poppins', fontSize: '15px', color: "black", marginTop: '13px' }}>{data?.eoCompanyName ? data.eoCompanyName : "N/A"}</span>
                                        </div>

                                        <div style={{ display: 'grid', marginRight: '20px', marginTop: '25px' }}>
                                            <span style={{ font: 'normal normal 400 14px/14px Poppins', fontSize: '14px', color: "black" }}>E & O Policy Number</span>
                                            <span style={{ font: 'normal normal 600 15px/25px Poppins', fontSize: '15px', color: "black", marginTop: '13px' }}>{data?.policy ? data.policy : "N/A"}</span>
                                        </div>
                                    </>

                                )}
                                <div style={{ display: 'grid', marginRight: '20px', marginTop: '25px' }}>
                                    <span style={{ font: 'normal normal 400 14px/14px Poppins', fontSize: '14px', color: "black" }}>Address</span>
                                    <span style={{ font: 'normal normal 600 15px/25px Poppins', fontSize: '15px', color: "black", marginTop: '13px' }}>{data?.address ? data.address : "N/A"}</span>
                                </div>

                                <div style={{ display: 'grid', marginRight: '20px', marginTop: '25px' }}>
                                    <span style={{ font: 'normal normal 400 14px/14px Poppins', fontSize: '14px', color: "black" }}>City</span>
                                    <span style={{ font: 'normal normal 600 15px/25px Poppins', fontSize: '15px', color: "black", marginTop: '13px' }}>{data?.city ? data.city : "N/A"}</span>
                                </div>

                                <div style={{ display: 'grid', marginRight: '20px', marginTop: '25px' }}>
                                    <span style={{ font: 'normal normal 400 14px/14px Poppins', fontSize: '14px', color: "black" }}>State</span>
                                    <span style={{ font: 'normal normal 600 15px/25px Poppins', fontSize: '15px', color: "black", marginTop: '13px' }}>{data?.state ? data.state : "N/A"}</span>
                                </div>

                                <div style={{ display: 'grid', marginRight: '20px', marginTop: '25px' }}>
                                    <span style={{ font: 'normal normal 400 14px/14px Poppins', fontSize: '14px', color: "black" }}>Company Name</span>
                                    <span style={{ font: 'normal normal 600 15px/25px Poppins', fontSize: '15px', color: "black", marginTop: '13px' }}>{data?.companyName ? data.companyName : "N/A"}</span>
                                </div>

                                <div style={{ display: 'grid', marginRight: '20px', marginTop: '25px' }}>
                                    <span style={{ font: 'normal normal 400 14px/14px Poppins', fontSize: '14px', color: "black" }}>Company Address</span>
                                    <span style={{ font: 'normal normal 600 15px/25px Poppins', fontSize: '15px', color: "black", marginTop: '13px' }}>{data?.companyAddress1 ? data.companyAddress1 : "N/A"} {data?.companyAddress2}</span>
                                </div>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: "6px",
                                marginLeft: "40px",
                                marginBottom: "-17px"
                            }}
                        >
                            {header.map((item, index) => (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: pageType === item.value ? "rgba(239,238,238,1)" : "#F8F8F8 0% 0% no-repeat padding-box",
                                        height: "59px",
                                        width: "189px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        color: pageType === item.value ? "#2D81FF" : "#000000",
                                        margin: "0px 4px",
                                        paddingTop: "2px",
                                        position: "relative",
                                        opacity: pageType === item.value ? 1 : 0.3,
                                        borderRadius: "20px 20px 0px 0px;"
                                    }}
                                >
                                    <Box
                                        onClick={() => getAllGridData(item.value)}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            height: "100%",
                                            width: "100%",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                color:
                                                    pageType === item.value ? "#2593D6 !important" : "#000000",
                                            }}
                                        >
                                            <Icon
                                                name={item.value === pageType ? item.selectedIcon : item.icon}
                                                style={{
                                                    width: "17px",
                                                    height: "19px",
                                                    marginTop: "6px",
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                font: "normal normal normal 13px Poppins",
                                                color: pageType === item.value ? "#2593D6" : "#000000",
                                                marginLeft: "10px",
                                            }}
                                        >
                                            {item.label}
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </Box>

                        <Box
                            sx={{
                                borderRadius: "16px",
                                background: "rgba(239,238,238,1)",
                                opacity: 1,
                                padding: "1.5em",
                                mt: "1em",
                            }}
                        >
                            {isLoading2 ? (
                                <CircularProgress
                                    size={40}
                                    sx={{
                                        color: "#2C80FF",
                                        marginRight: "6px",
                                        display: "flex",
                                        margin: "Auto"
                                    }}
                                />
                            ) : (
                                <>
                                    {gridData && gridData.length ? (
                                        gridData.map((item, index) => (
                                            <PropertyCard key={index} data={item} type={pageType} onSubmit={() => onSubmit(item._id)} errors={errors} handleChange={(data, value) => handleChange(data, value)} counterData={modifyOrder} />
                                        ))
                                    ) : (
                                        <h1 style={{ font: "normal normal normal 13px Poppins", textAlign: "center", color: "gray" }}>No data</h1>
                                    )}
                                </>
                            )}
                        </Box>
                    </>
                )}
            </Box>

        </Template>
    );
};

export default UserDetail;
