import axios from "../Utils/axios";
import { dashboardConstants } from "./constants";
import { apiUrls } from "../Helpers/Constants";

// Assuming your getAdmins action creator is defined like this
export const getAnalyticCount = (params) => {
    return async (dispatch) => {
        dispatch({ type: dashboardConstants.ANALYTICS_REQUEST });

        try {
            const res = await axios.get(apiUrls.getAnalyticsCount, { params });

            if (res.status === 200) {
                dispatch({
                    type: dashboardConstants.ANALYTICS_SUCCESS,
                    payload: {
                        data: res.data.data,
                        message: res.data.message,
                    },
                });

                // console.log("res",res.data)

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: dashboardConstants.ANALYTICS_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch data'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getOrderHistory = () => {
    return async (dispatch) => {
        dispatch({ type: dashboardConstants.ORDER_HISTORY_REQUEST });

        try {
            const res = await axios.get(apiUrls.getOrdersHistory);

            if (res.status === 200) {
                dispatch({
                    type: dashboardConstants.ORDER_HISTORY_SUCCESS,
                    payload: {
                        data: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: dashboardConstants.ORDER_HISTORY_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch data'); // Throw an error to be caught by the component
            }
        } catch (error) {
            // console.error("Error fetching data:", error);
            // throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getRevisionHistory = () => {
    return async (dispatch) => {
        dispatch({ type: dashboardConstants.REVISION_HISTORY_REQUEST });

        try {
            const res = await axios.get(apiUrls.getRevisionHistory);

            if (res.status === 200) {
                dispatch({
                    type: dashboardConstants.REVISION_HISTORY_SUCCESS,
                    payload: {
                        data: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: dashboardConstants.REVISION_HISTORY_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch data'); // Throw an error to be caught by the component
            }
        } catch (error) {
            // console.error("Error fetching data:", error);
            // throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getTopUsers = (params) => {
    return async (dispatch) => {
        dispatch({ type: dashboardConstants.TOP_USER_REQUEST });

        try {
            const res = await axios.get(apiUrls.getTopUsers, { params });

            if (res.status === 200) {
                dispatch({
                    type: dashboardConstants.TOP_USER_SUCCESS,
                    payload: {
                        data: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: dashboardConstants.TOP_USER_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch data'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getTopClients = () => {
    return async (dispatch) => {
        dispatch({ type: dashboardConstants.TOP_CLIENT_REQUEST });

        try {
            const res = await axios.get(apiUrls.getTopClients);

            if (res.status === 200) {
                dispatch({
                    type: dashboardConstants.TOP_CLIENT_SUCCESS,
                    payload: {
                        data: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: dashboardConstants.TOP_CLIENT_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch data'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};
