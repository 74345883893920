import { Navigate, Route, Routes } from "react-router";
import "./App.css";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import Dashboard from "./Pages/Dashboard";
import UserManagement from "./Pages/UserManagement";
import AdminManagement from "./Pages/AdminManagement";
import Message from "./Pages/Messages";
import Support from "./Pages/Support";
import Accounting from "./Pages/Accounting";
import UserDetail from "./Pages/UserDetail";
import EditProfile from "./Pages/EditProfile";
import { useDispatch, useSelector } from "react-redux";
import { isUserLoggedIn } from "./Actions/auth";
import { useEffect } from "react";

function App() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isUserLoggedIn());
    }
  }, [auth.authenticate]);

  return (
    <Routes>
      <Route
        path="/"
        element={auth.authenticate ? <Dashboard /> : <Navigate to="/login" />}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/user-management" element={<UserManagement />} />
      <Route path="/admin-management" element={<AdminManagement />} />
      <Route path="/message" element={<Message />} />
      <Route path="/support-token" element={<Support />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/accounting" element={<Accounting />} />
      <Route path="/detail" element={<UserDetail />} />
      <Route path="/edit-profile" element={<EditProfile />} />
      <Route path="*" element={<h1>Not Found</h1>} />
    </Routes>
  );
}

export default App;
