import React, { useState, useEffect } from "react";
import { Box } from '@mui/material';
import Icon from "../Helpers/Icon";
import { getImageUrl } from '../Helpers/Constants';
import Button from "./Core/Button";
import Attachment from "./Attachment";
import CreateCounter from "./CreateCounter";
import Modal from '@mui/material/Modal';
const moment = require('moment');

const PropertyCard = ({ isShow, type, data, onSubmit, errors, handleChange, counterData }) => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => { setOpen(true); };
    const handleClose = () => setOpen(false);


    return (
        <Box sx={{
            display: 'flex',
            height: "80px",
            width: "96%",
            background: "#F8F8F8 0% 0% no-repeat padding-box",
            borderRadius: "12px",
            marginRight: "20px",
            marginBottom: "16px",
            paddingLeft: "21px",
            paddingRight: "24px",
        }}>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
                <img alt='images'
                    src={getImageUrl(data?.images && data?.images.length ? data?.images[0] : data?.images)}
                    style={{
                        width: "90px",
                        height: "70px",
                        borderRadius: "10px"
                    }}
                />

                {type === 'Property' && (
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        <span style={{
                            color: "#000000",
                            fontSize: "12px",
                            font: "normal normal normal 12px/12px Poppins",
                            marginLeft: "10px",
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                            marginTop: "5px"
                        }}>{data?.address}</span>
                        <span style={{
                            color: "#000000",
                            fontSize: "12px",
                            font: "normal normal normal 12px/12px Poppins",
                            whiteSpace: "nowrap",
                            marginTop: "5px"
                        }}>,{data?.state}</span>
                        <span style={{
                            color: "#000000",
                            fontSize: "12px",
                            font: "normal normal normal 12px/12px Poppins",
                            whiteSpace: "nowrap",
                            marginTop: "5px"
                        }}>,{data?.city}</span>
                        <span style={{
                            color: "#000000",
                            fontSize: "12px",
                            font: "normal normal normal 12px/12px Poppins",
                            marginRight: "14px",
                            whiteSpace: "nowrap",
                            marginTop: "5px"
                        }}>,{data?.zipCode}</span>
                    </div>
                )}

                {type === 'Orders' && (
                    <span style={{
                        color: "#000000",
                        fontSize: "12px",
                        font: "normal normal normal 12px/12px Poppins",
                        marginLeft: "10px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap"
                    }}>Order ID: #{data?.orderId}</span>
                )}

            </Box>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                borderRadius: '5px',
            }}>
                <div className='iconContainer' style={{ width: '100%', display: "flex" }}>
                    <Icon
                        name="Grid1"
                        style={{
                            width: "17px",
                            height: "17px",
                            marginRight: "15px"
                        }}
                    />

                    <span style={{ color: '#000000', fontSize: '11px', font: 'normal normal normal 11px/19px Poppins', whiteSpace: "nowrap" }}>Property ID: #{data?.propertyId}</span>
                </div>

                <div className='iconContainer' style={{ width: '55%', display: "flex" }}>
                    <Icon
                        name="Grid2"
                        style={{
                            marginTop: "4px",
                            width: "17px",
                            height: "17px",
                            marginRight: "15px"
                        }}
                    />

                    <span style={{ color: '#000000', fontSize: '11px', font: 'normal normal normal 11px/19px Poppins', whiteSpace: "nowrap" }}>{moment(data?.createdAt).format('YY/MM/DD')}</span>
                </div>

                <div className='iconContainer' style={{ width: '55%', display: "flex" }}>
                    <Icon
                        name="Grid3"
                        style={{
                            marginTop: "4px",
                            width: "17px",
                            height: "17px",
                            marginRight: "15px"
                        }}
                    />

                    <span style={{ color: '#000000', fontSize: '11px', font: 'normal normal normal 11px/19px Poppins', whiteSpace: "nowrap" }}>{data?.user?.firstName} {data?.user?.lastName}</span>
                </div>

                <div className='iconContainer' style={{ width: '55%', display: "flex" }}>
                    <Icon
                        name="Grid4"
                        style={{
                            marginTop: "4px",
                            width: "17px",
                            height: "17px",
                            marginRight: "15px"
                        }}
                    />

                    <span style={{ color: '#000000', fontSize: '11px', font: 'normal normal normal 11px/19px Poppins', whiteSpace: "nowrap" }}>{data?.propertyType}</span>
                </div>
            </Box>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '24%',
                borderRadius: '5px',
            }}>
                {type === 'Property' && (
                    <div className='iconContainer' style={{ width: '100%', display: "flex" }} onClick={handleOpen}>
                        <Icon
                            name="Attachment"
                            style={{
                                marginTop: "4px",
                                width: "15px",
                                height: "15px",
                                marginRight: "15px"
                            }}
                        />

                        <span style={{ color: '#2C80FF', fontSize: '11px', font: 'normal normal normal 11px/19px Poppins', whiteSpace: "nowrap" }}>View Attachment</span>
                    </div>
                )}

                {type === 'Orders' && (
                    <div className='iconContainer' style={{ width: '100%' }}>
                        <Button onClick={handleOpen}
                            icon="Modify"
                            style={{
                                width: "202px",
                                marginTop: "auto",
                                marginBottom: "14px",
                                marginLeft: "15px",
                                height: "47px",
                            }}
                        >
                            Modify Order
                        </Button>
                    </div>
                )}

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            mb: "1em",
                            width: "88%",
                            margin: "auto",
                            marginTop: "5em"
                        }}
                    >
                        {type === 'Property' && (
                            <Attachment images={data.images} documents={data.documents} handleClose={() => handleClose()} />
                        )}

                        {type === 'Orders' && (
                            <CreateCounter errors={errors} handleClose={handleClose} onSubmit={() => onSubmit()} handleChange={(data, value) => handleChange(data, value)} data={counterData} />
                        )}
                    </Box>
                </Modal>
            </Box>
        </Box>
    );
}

export default PropertyCard;