import { Box, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import Template from "../../Template";
import Ellipse8 from "../../Assets/Images/Ellipse8.svg";
import { clientHeader } from '../../Helpers/DataHelper';
import Icon from "../../Helpers/Icon";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail, getClientDetail, updateUserDetail } from "../../Actions/users";
import { getAdminDetail, updateAdminDetail } from "../../Actions/admins";
import AlertBox from "../../Components/Alert";
import Button from "../../Components/Core/Button";
import { getStates } from "../../Actions/state";
import Input from "../../Components/Core/Input";
import Select from "../../Components/Core/Select";
import { useLocation } from "react-router";
import { useNavigate } from 'react-router-dom';
import { getInitials, getStatus } from '../../Helpers/Constants';
import { clientInfoSchema, usrInfoSchema } from "../../Validations";
import ChangePassword from "../../Components/ChangePassword";



const EditUser = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { type, id } = location.state;
    const auth = useSelector((state) => state.auth);

    
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [states, setStates] = useState([]);
    const [showMessage, setshowMessage] = useState(false);
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        cellPhone: '',
        state: '',
        address: '',
        city: '',
        zipCode: '',
        companyName: '',
        companyPhoneNumber: '',
        companyAddress1: '',
        companyAddress2: '',
        companyState: '',
        companyCity: '',
        companyZipCode: ''
    });
    const [data, setData] = useState([]);
    const [alert, setAlert] = useState({ show: false, type: "", message: "" });

    useEffect(() => {
        getStatesData();
        if (type === 'my-profile') {
            getAdminDetails();
        } else {
            getUserDetailData();
        }

    }, []);

    const getStatesData = async () => {
        setIsLoading(true);
        setStates([])
        try {
            const result = await dispatch(getStates());
            if (result && result.data && result.data.length) {
                var stateList = [];
                result.data.forEach(element => {
                    const data = {
                        ...element,
                        value: element.name,
                        label: element.name
                    }
                    stateList.push(data);
                });
                setStates(stateList)
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error occurred while fetching states data:", error);

            setIsLoading(false);
            setAlert({ show: true, type: 'error', message: error?.response?.data?.error || "An error occurred." });
        }
    }

    const getUserDetailData = async () => {
        setIsLoading(true);
        setData([])

        try {
            const result = await dispatch(type === 'Client' ? getClientDetail(id) : getUserDetail(id));
            if (result && result.data) {
                type === 'Client' ? setData(result.data) : setData(result.data[0]);
                const user = type === 'Client' ? result.data : result.data[0];
                const resultData = {
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    email: user?.email,
                    cellPhone: user?.cellPhone,
                    state: user?.state,
                    address: user?.address,
                    city: user?.city,
                    zipCode: user?.zipCode,
                    companyName: user?.companyName,
                    companyPhoneNumber: user?.companyPhoneNumber,
                    companyAddress1: user?.companyAddress1,
                    companyAddress2: user?.companyAddress2,
                    companyState: user?.companyState,
                    companyCity: user?.companyCity,
                    companyZipCode: user?.companyZipCode,
                }

                setUserData(resultData)
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error occurred while fetching user data:", error);

            setIsLoading(false);
            setAlert({ show: true, type: 'error', message: error?.response?.data?.error || "An error occurred." });
        }
    };

    const getAdminDetails = async () => {
        setIsLoading(true);
        setData([])

        try {
            const result = await dispatch(getAdminDetail(id));
            if (result && result.data) {
                const user = result.data;
                const resultData = {
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    email: user?.email,
                    cellPhone: user?.cellPhone,
                }

                setUserData(resultData)
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error occurred while fetching user data:", error);

            setIsLoading(false);
            setAlert({ show: true, type: 'error', message: error?.response?.data?.error || "An error occurred." });
        }
    };

    const handleChange = (field, value) => {
        setUserData(prevData => {
            return {
                ...prevData,
                [field]: value,
            };
        });
    };

    const goBack = () => {
        if (type === 'my-profile') {
            navigate('/dashboard');
        } else {
            navigate('/detail', { state: { type: type, id: id } });
        }
    }

    const updateProfile = async () => {
        setIsLoading(true);
        try {
            if (type === 'my-profile' || type === 'Client') {
                await clientInfoSchema.validate(userData, {
                    abortEarly: false,
                });
            } else {
                await usrInfoSchema.validate(userData, {
                    abortEarly: false,
                });
            }

            const data = {
                firstName: userData.firstName,
                lastName: userData.lastName,
                cellPhone: userData.cellPhone,
                email: userData.email
            }

            getApiLink(data).then((result) => {
                setIsLoading(false);
                setAlert({ show: true, type: 'success', message: "Updated Successfully successfully" });
                if (type === 'my-profile') {
                    getAdminDetails();
                } else {
                    getUserDetailData();
                }
            })
                .catch((err) => {
                    setIsLoading(false);
                    setAlert({ show: true, type: 'error', message: err?.response.data?.error });
                });
        } catch (validationError) {
            const formattedErrors = {};
            validationError.inner.forEach((error) => {
                formattedErrors[error.path] = error.message;
            });

            setErrors(formattedErrors);
            setIsLoading(false);
        }
    }

    const getApiLink = async (data) => {
        if (type === 'my-profile') {
            return dispatch(updateAdminDetail(id, data));
        } else {
            return dispatch(updateUserDetail(id, userData))
        }
    }

    const changePassword = () => {
         
    }

    return (
        <Template
            showHeader
            showSidebar={false}
            headerProps={{
                showSearch: true,
                showNotification: true,
                showProfile: true
            }}
            sidebarProps={{
                page: "User Management"
            }}
         
            containerStyle={{
                backgroundImage: `url(${Ellipse8})`,
            }}
        >
            <AlertBox open={alert.show} type={alert.type} message={alert.message} onChange={(value) => { setAlert({ show: value, type: '', message: "" }); }} />

            <Box
                sx={{
                    background: "transparent",
                    opacity: "1"
                }}
            >

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "20px",
                        marginBottom: "40px",
                        justifyContent: "space-between",
                        background: "transparent 0% 0% no-repeat padding-box",
                        boxShadow: "15px 15px 50px #00000029",
                        backdropFilter: "blur(50px)",
                        WebkitBackdropFilter: "blur(50px)",
                        height: "87px",
                    }}
                >

                    {type === "my-profile" ? (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                padding: "0.8em 0",
                                cursor: "pointer",
                                marginLeft: "4em"
                            }}
                        >
                            <Box
                                sx={{
                                    boxShadow: "0px 3px 6px #00000029",
                                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                                    borderRadius: "50%",
                                    padding: ".5em",
                                    height: "40px",
                                    width: "40px",
                                    display: "flex",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        font: " normal normal 600 22px/23px Poppins",
                                        color: "#2C80FF",
                                    }}
                                >
                                    {getInitials(auth.user?.firstName, auth.user?.lastName)}
                                </span>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "70%",
                                    m: "auto 0",
                                }}
                            >
                                <span
                                    style={{
                                        font: " normal normal 600 15px/23px Poppins",
                                        color: "#000000",
                                        marginLeft: "0.7em",
                                    }}
                                >
                                    {auth.user?.firstName} {auth.user?.lastName}
                                </span>

                                <span
                                    style={{
                                        font: "normal normal normal 12px/18px Poppins",
                                        color: "#656A72",
                                        marginLeft: "0.7em",
                                    }}
                                >
                                    {auth.user?.email}
                                </span>
                            </Box>
                        </Box>
                    ) : (
                        <span className="allTransaction" style={{ font: 'normal normal 600 26px/58px Poppins', color: '#011502', fontSize: '26px', marginLeft: '3em' }}>Edit {type} Detail</span>
                    )}
                    <span onClick={goBack} className="allTransaction" style={{ font: 'normal normal 600 17px/28px Poppins', color: '#2C80FF', fontSize: '17px', marginRight: "64px", cursor: "pointer" }}>Back</span>
                </Box>

                {isLoading ? (
                    <CircularProgress
                        size={40}
                        sx={{
                            color: "#2C80FF",
                            marginRight: "6px",
                            display: "flex",
                            margin: "Auto"
                        }}
                    />
                ) : (
                    <>
                        <span className="allTransaction" style={{ font: 'normal normal 600 16px/26px Poppins', color: '#000000', fontSize: '16px', marginLeft: '5em', marginBottom: "20px" }}>Basic Information</span>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: "40px",
                                marginLeft: '5em',
                                width: "86%",
                                marginTop: "1em",
                                flexWrap: "wrap"
                            }}
                        >
                            <Input
                                placeholder="First Name"
                                label="First Name"
                                type="text"
                                value={userData.firstName}
                                onChange={(e) => handleChange("firstName", e.target.value)}
                                style={{ width: "25%", marginLeft: "2em", marginBottom: "2em" }}
                                error={errors.firstName || ""}
                            />

                            <Input
                                placeholder="Last Name"
                                label="Last Name"
                                type="text"
                                style={{ width: "25%", marginLeft: "2em", marginBottom: "2em" }}
                                value={userData.lastName}
                                onChange={(e) => handleChange("lastName", e.target.value)}
                                error={errors.lastName || ""}
                            />

                            <Input
                                placeholder="Email"
                                label="Email"
                                type="text"
                                style={{ width: "25%", marginLeft: "2em", marginBottom: "2em", cursor: "none" }}
                                value={userData.email}
                                onChange={(e) => handleChange("email", e.target.value)}
                                error={errors.email || ""}
                            />

                            <Input
                                placeholder="Cell Phone"
                                label="Cell Phone"
                                type="number"
                                style={{ width: "25%", marginLeft: "2em", marginBottom: "2em" }}
                                value={userData.cellPhone}
                                onChange={(e) => handleChange("cellPhone", e.target.value)}
                                error={errors.cellPhone || ""}
                            />
                        </Box>

                        {type !== "my-profile" && (
                            <>
                                <span className="allTransaction" style={{ font: 'normal normal 600 16px/26px Poppins', color: '#000000', fontSize: '16px', marginLeft: '5em', marginBottom: "20px" }}>Address</span>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginBottom: "40px",
                                        marginLeft: '5em',
                                        width: "86%",
                                        marginTop: "1em",
                                        flexWrap: "wrap"
                                    }}
                                >
                                    <Input
                                        placeholder="Street Address / PO Box"
                                        label="Street Address / PO Box"
                                        type="text"
                                        value={userData.address}
                                        onChange={(e) => handleChange("address", e.target.value)}
                                        style={{ width: "25%", marginLeft: "2em", marginBottom: "2em" }}
                                        error={errors.address || ""}
                                    />

                                    <Select
                                        placeholder="State"
                                        label="State"
                                        style={{ width: "25%", marginLeft: "2em", marginBottom: "2em" }}
                                        options={states}
                                        value={userData.state}
                                        onChange={(option) => {
                                            handleChange("state", option);
                                        }}
                                        error={errors.state || ""}
                                    />

                                    <Input
                                        placeholder="City / Town"
                                        label="City / Town"
                                        type="text"
                                        style={{ width: "25%", marginLeft: "2em", marginBottom: "2em" }}
                                        value={userData.city}
                                        onChange={(e) => handleChange("city", e.target.value)}
                                        error={errors.city || ""}
                                    />

                                    <Input
                                        placeholder="Zip Code"
                                        label="Zip Code"
                                        type="text"
                                        style={{ width: "25%", marginLeft: "2em", marginBottom: "2em" }}
                                        value={userData.zipCode}
                                        onChange={(e) => handleChange("zipCode", e.target.value)}
                                        error={errors.zipCode || ""}
                                    />
                                </Box>

                                <span className="allTransaction" style={{ font: 'normal normal 600 16px/26px Poppins', color: '#000000', fontSize: '16px', marginLeft: '5em', marginBottom: "20px" }}>Company Info</span>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginBottom: "40px",
                                        marginLeft: '5em',
                                        width: "86%",
                                        marginTop: "1em",
                                        flexWrap: "wrap"
                                    }}
                                >
                                    <Input
                                        placeholder="Company Name"
                                        label="Company Name"
                                        type="text"
                                        value={userData.companyName}
                                        onChange={(e) => handleChange("companyName", e.target.value)}
                                        style={{ width: "25%", marginLeft: "2em", marginBottom: "2em" }}
                                        error={errors.companyName || ""}
                                    />

                                    <Input
                                        placeholder="Company Phone Number"
                                        label="Company Phone Number"
                                        type="number"
                                        style={{ width: "25%", marginLeft: "2em", marginBottom: "2em" }}
                                        value={userData.companyPhoneNumber}
                                        onChange={(e) => handleChange("companyPhoneNumber", e.target.value)}
                                        error={errors.companyPhoneNumber || ""}
                                    />

                                    <Input
                                        placeholder="Company Address 1"
                                        label="Street Address / PO Box"
                                        type="text"
                                        style={{ width: "25%", marginLeft: "2em", marginBottom: "2em" }}
                                        value={userData.companyAddress1}
                                        onChange={(e) => handleChange("companyAddress1", e.target.value)}
                                        error={errors.companyAddress1 || ""}
                                    />

                                    <Input
                                        placeholder="Company Address 2"
                                        label="Apartment / Unit Number"
                                        type="text"
                                        style={{ width: "25%", marginLeft: "2em", marginBottom: "2em" }}
                                        value={userData.companyAddress2}
                                        onChange={(e) => handleChange("companyAddress2", e.target.value)}
                                        error={errors.companyAddress2 || ""}
                                    />

                                    <Select
                                        placeholder="State"
                                        label="State"
                                        style={{ width: "25%", marginLeft: "2em", marginBottom: "2em" }}
                                        options={states}
                                        value={userData.companyState || []}
                                        onChange={(option) => {
                                            handleChange("companyState", option);
                                        }}
                                        error={errors.companyState || ""}
                                    />

                                    <Input
                                        placeholder="City *"
                                        label="City *"
                                        type="text"
                                        style={{ width: "25%", marginLeft: "2em", marginBottom: "2em" }}
                                        value={userData.companyCity}
                                        onChange={(e) => handleChange("companyCity", e.target.value)}
                                        error={errors.companyCity || ""}
                                    />

                                    <Input
                                        placeholder="Zip Code *"
                                        label="Zip Code *"
                                        type="text"
                                        style={{ width: "25%", marginLeft: "2em", marginBottom: "2em" }}
                                        value={userData.companyZipCode}
                                        onChange={(e) => handleChange("companyZipCode", e.target.value)}
                                        error={errors.companyZipCode || ""}
                                    />


                                </Box>
                            </>
                        )}

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: "5em",
                                marginLeft: '5em',
                            }}
                        >
                            <Button
                                style={{
                                    width: "12em",
                                    marginTop: "auto",
                                    marginBottom: "14px",
                                    height: "40px",
                                    marginRight: "2em"
                                }}
                                onClick={updateProfile}
                            >
                                Update Profile
                            </Button>

                            {type === "my-profile" && (
                                <Button
                                    style={{
                                        width: "12em",
                                        marginTop: "auto",
                                        marginBottom: "14px",
                                        height: "40px",
                                        border: "1px solid #2C80FF",
                                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                                        color: "#2C80FF"
                                    }}
                                    onClick={() => setshowMessage(!showMessage)}
                                >
                                    Change Password
                                </Button>

                            )}
                        </Box>
                    </>


                )}

        {showMessage && (
          <ChangePassword
            handleClose={() => setshowMessage(!showMessage)}
          />
        )}
            </Box>

        </Template>
    );
};

export default EditUser;
