import axios from "../Utils/axios";
import { authConstants, completeProfileConstants } from "./constants";
import { apiUrls } from "../Helpers/Constants";

export const registerUser = (data) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.REGISTER_REQUEST });
    const res = await axios.post("/auth/signup", data);

    if (res.status === 200) {
      dispatch({
        type: authConstants.REGISTER_SUCESS,
        payload: {
          user: res.data.status,
          message: "User Saved Successfully",
        },
      });
    } else {
      if (res.status === 400) {
        dispatch({
          type: authConstants.REGISTER_FAILURE,
          payload: { error: res.data },
        });
      }
    }
  };
};

export const otpRequest = (data) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.OTP_REQUEST });
    const res = await axios.post(apiUrls.sendOtp, data);

    console.log(res);
    if (res.status === 200) {
      dispatch({
        type: authConstants.OTP_SUCCESS,
        payload: {
          user: res.data.data,
          role: data.type,
          message: "OTP Send Successfully",
        },
      });
    } else {
      if (res.status === 400) {
        dispatch({
          type: authConstants.OTP_FAILURE,
          payload: { error: res.data },
        });
      }
    }
  };
};

export const verifyOtp = (data) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.OTP_REQUEST });
    const res = await axios.post(apiUrls.verifyOtp, data);
    if (res.status === 200) {
      dispatch({
        type: authConstants.OTP_SUCCESS,
        payload: {
          user: res.data.data,
          message: "OTP verified successfully",
        },
      });
    } else {
      if (res.status === 400) {
        dispatch({
          type: authConstants.OTP_FAILURE,
          payload: { error: res.data },
        });
      }
    }
  };
};

export const loginUser = (data) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST });
    const res = await axios.post(apiUrls.login, data);
    if (res.status === 200) {
      const { token } = res.data.data;
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(res.data.data));
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          token,
          user: res.data.data,
          message: "Login Successfully",
        },
      });
    } else {
      if (res.status === 400) {
        dispatch({
          type: authConstants.LOGIN_FAILURE,
          payload: { error: res.data },
        });
      }
    }
  };
};

export const resetPassword = (data) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.RESET_PASSWORD_REQUEST });
    const res = await axios.post(apiUrls.resetPassword, data);
    if (res.status === 200) {
      dispatch({
        type: authConstants.RESET_PASSWORD_SUCCESS,
        payload: {
          user: res.data.data,
          message: "Reset Password Successfully",
        },
      });
    } else {
      if (res.status === 400) {
        dispatch({
          type: authConstants.RESET_PASSWORD_FAILURE,
          payload: { error: res.data },
        });
      }
    }
  };
};

export const isUserLoggedIn = () => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST });
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const decryptedUser = JSON.parse(user);

    if (token) {
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          token,
          user: decryptedUser,
          message: "Login Successfully",
        },
      });
    } else {
      dispatch({
        type: authConstants.LOGOUT_FAILURE,
        payload: { error: "Failed to login" },
      });
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch({
      type: authConstants.LOGOUT_REQUEST,
    });
    localStorage.clear();
    dispatch({
      type: authConstants.LOGOUT_SUCESS,
    });
  };
};

export const addPersonal = (data) => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.ADD_PERSONAL_REQUEST });
    const res = await axios.put("/users", data);
    if (res.status === 200) {
      window.localStorage.setItem("completion", "Personal Information");
      dispatch({
        type: completeProfileConstants.ADD_PERSONAL_SUCCESS,
        payload: {
          user: res.data.data,
          message: "Personal Added Sucessfully",
        },
      });
    } else {
      if (res.status === 400) {
        dispatch({
          type: completeProfileConstants.ADD_PERSONAL_FAILURE,
          payload: { error: res.data },
        });
      }
    }
  };
};
