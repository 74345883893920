import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import MorePopUp from './MorePopUp';
import Button from "./Core/Button";

const Grid = ({ type, data, columns, isLoading, suspendAccount, deleteAccount, showButtons, activateUser, rejectUser, onRowClick }) => {

  const getRowId = (row) => row._id;

  const menuColumn = {
    renderCell: (params) =>
    <MorePopUp 
    rowData={params.row} anchorPosition={{ top: params.row.yourTopValue, left: params.row.yourLeftValue }} suspendAccount={suspendAccount} deleteAccount={deleteAccount} />,
  };

  const superAdminColumn = {
    renderCell: (params) =>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "initial",
        }}
      ></Box>
  };

  const buttonsColumn = {
    renderCell: (params) =>
      
      <Box
      style={{
        position:"absolute",
        right:"60px"
      }}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems:"center",
          justifyContent: "initial",
        }}
      >
        <Button
          style={{
            // marginTop: "auto",
            // marginBottom: "14px",
            height: "34px",
            width: '6em',
            marginLeft:'2em'
          }}
          onClick={() => activateUser(params.row._id)}
        >
          Approve
        </Button>
        <Button
          style={{
            // marginTop: "auto",
            // marginBottom: "14px",
            height: "34px",
            backgroundColor: "#f6cfcf",
            color: "#EF2B2C",
            marginLeft: "5px",
            width: '6em',
             marginLeft:'2em'
          }}
          onClick={() => rejectUser(params.row._id)}
        >
          Reject
        </Button>
      </Box>
  };


  const updatedColumns = type === "Super Admin" ? [...columns, superAdminColumn] : [...columns, showButtons ? buttonsColumn : menuColumn];

  return (
    <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: showButtons ? "32em" : "50em",
      minHeight: "16em",
      width: "100%",
    }}>
      {isLoading ? (
        <CircularProgress
          size={40}
          sx={{
            color: "#2C80FF",
            marginRight: "6px",
            display: "flex",
            margin: "Auto"
          }}
        />
      ) : (
        <DataGrid
        rowHeight={37} 
        rowSpacingType='margin'
        getRowSpacing={(para)=>({
          top:para.isFirstVisible?4:6,
          bottom:para.isLastVisible?4:6
        })}
          rows={data}
          columns={updatedColumns.map((column) => ({
            ...column,
            flex: 1,
            wrap: true,
          }))}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          autoPageSize
          pageSizeOptions={[10]}
          getRowId={getRowId}
          onRowClick={onRowClick}
          disableRowSelectionOnClick  // Disable row selection
          disableColumnMenu           // Disable column menu
          disableColumnResize         // Disable column resizing
        />
      )}

    </Box>
  );
}

export default Grid;