import { getInitials, getStatus } from './Constants';
import { Box } from "@mui/material";

export const userHeaders = [
    {
        label: "Clients",
        value: "Client",
        icon: "User",
        selectedIcon: "UserSelected",
    },
    {
        label: "Appraisers",
        value: "Appraiser",
        icon: "File",
        selectedIcon: "FileSelected",
    },
    {
        label: "Inspectors",
        value: "Inspector",
        icon: "Inspector",
        selectedIcon: "InspectorSelected",
    },
    {
        label: "Reviewers",
        value: "Reviewer",
        icon: "Reviewer",
        selectedIcon: "ReviewerSelected",
    },
];

export const adminHeaders = [
    {
        label: "Super Admin",
        icon: "Inspector",
        selectedIcon: "InspectorSelected",
    },
    {
        label: "Admin",
        icon: "File",
        selectedIcon: "FileSelected",
    }
];

export const chatHeaders = [
    {
        label: "All Support Ticket",
        value: "all",
        icon: "SubstractSelected",
        selectedIcon: "Substract",
    },
    {
        label: "Open Ticket",
        value: "open",
        icon: "OpenTicket",
        selectedIcon: "OpenTicketSelected",
    },
    {
        label: "Closed Ticket",
        value: "closed",
        icon: "ClosedTicket",
        selectedIcon: "ClosedTicketSelected",
    }
];

const renderCellContent = (params) => (
    <div style={{ display: 'flex', alignItems: "center", marginLeft: "-10px" }}>
        <Box
            sx={{
                background: "#deecf4 0% 0% no-repeat padding-box",
                borderRadius: "11px",
                height: "55px",
                mt: "-2px",
                width: "52px",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                alignItems: "center",
            }}
        >
            <span
                style={{
                    font: " normal normal 600 15px/23px Poppins",
                    color: "#2593D6",
                    fontSize: "18px"
                }}
            >
                {getInitials(params.row.firstName, params.row.lastName)}
            </span>
        </Box>

        <span style={{ color: "#132843", fontSize: "13px", marginLeft: "10px" }}>
            {params.row.firstName || ''} {params.row.lastName || ''}
        </span>
    </div>
);

const renderStatusCellContent = (params) => (
    <div style={{ display: 'flex', alignItems: "right", marginLeft: "-10px" }}>
        <Box
            sx={{
                display: "flex",
                justifyContent: "right",
                cursor: "pointer",
                alignItems: "right",
            }}
        >
            <span
                style={{
                    font: " normal normal 600 15px/23px Poppins",
                    color: getStatus(params.row.status)?.color,
                    fontSize: "11px"
                }}
            >
                {getStatus(params.row.status)?.text}
            </span>
        </Box>
    </div>
);

const renderLicenseCellContent = (params) => (
    <div style={{ display: 'flex', alignItems: "right", marginLeft: "-10px" }}>
        <Box
            sx={{
                display: "flex",
                justifyContent: "right",
                cursor: "pointer",
                alignItems: "right",
            }}
        >
            {params.row.stateLicenses && params.row.stateLicenses.length && (
                <>
                    {
                        params.row.stateLicenses.map((item, index) => (
                            <span key={index}
                                style={{
                                    font: " normal normal 600 15px/23px Poppins",
                                    fontSize: "11px"
                                }}
                            >
                                {item.licenseNumber},
                            </span>
                        ))
                    }
                </>
            )}
        </Box>
    </div>
);

export const superAdminColumns = [
    {
        field: 'firstName',
        headerName: 'Name',
        renderCell: renderCellContent,
    },
    {
        field: 'email',
        headerName: 'Email',
    },
    {
        field: 'cellPhone',
        headerName: 'Phone Number',
        type: 'number',
    },
    {
        field: 'status',
        headerName: 'Status',
        renderCell: renderStatusCellContent
    },
    {
        field: 'totalModification',
        headerName: 'Total Modification',
        type: 'number',
    },
];

export const adminColumns = [
    {
        field: 'firstName',
        headerName: 'Name',
        renderCell: renderCellContent,
    },
    {
        field: 'email',
        headerName: 'Email',
    },
    {
        field: 'cellPhone',
        headerName: 'Phone Number',
        type: 'number',
    },
    {
        field: 'status',
        headerName: 'Status',
        renderCell: renderStatusCellContent
    },
    {
        field: 'activeOrders',
        headerName: 'Active Order',
        type: 'number',
    },
];


export const clientsColumns = [
    {
        field: 'firstName',
        headerName: 'Name',
        renderCell: renderCellContent,
    },
    {
        field: 'email',
        headerName: 'Email',
    },
    {
        field: 'cellPhone',
        headerName: 'Phone Number',
        type: 'number',
    },
    {
        field: 'totalProperties',
        headerName: 'Total Properties',
        type: 'number',
    },
    {
        field: 'address',
        headerName: 'Address',
    },
    {
        field: 'status',
        headerName: 'Status',
        renderCell: renderStatusCellContent
    },
    {
        field: 'totalOrders',
        headerName: 'Total Orders',
        type: 'number',
    },
];

export const usersColumns = [
    {
        field: 'firstName',
        headerName: 'Name',
        renderCell: renderCellContent,
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 250
    },
    {
        field: 'cellPhone',
        headerName: 'Phone Number',
        type: 'number',
    },
    {
        field: 'stateLicenses',
        headerName: 'License Number',
        renderCell: renderLicenseCellContent
    },
    {
        field: 'address',
        headerName: 'Address',
    },
    {
        field: 'status',
        headerName: 'Status',
        renderCell: renderStatusCellContent
    },
    {
        field: 'completedOrders',
        headerName: 'Completed Orders',
        type: 'number',
    },
];

export const analysisCard = {
    Client: {
        totalUsers: {
            label: "Total Users",
            icon: "TotalUser",
            value: 0,
        },
        totalThisMonth: {
            label: "Total User",
            icon: "ThisMonthUser",
            value: 0,
        },
        activeUsers: {
            label: "Active Users",
            icon: "ActiveUser",
            value: 0,
        }
    },
    Reviewer: {
        totalUsers: {
            label: "Total Reviewers",
            icon: "TotalReviewer",
            value: 0,
        },
        totalThisMonth: {
            label: "Total Reviewer",
            icon: "ThisMonthReviewer",
            value: 0,
        },
        activeUsers: {
            label: "Active Reviewers",
            icon: "ActiveReviewer",
            value: 0,
        }
    },
    Inspector: {
        totalUsers: {
            label: "Total Inspectors",
            icon: "TotalInspector",
            value: 0,
        },
        totalThisMonth: {
            label: "Total Inspector",
            icon: "ThisMonthInspector",
            value: 0,
        },
        activeUsers: {
            label: "Active Inspectors",
            icon: "ActiveInspector",
            value: 0,
        }
    },
    Appraiser: {
        totalUsers: {
            label: "Total Appraisers",
            icon: "TotalAppraisers",
            value: 0,
        },
        totalThisMonth: {
            label: "Total Appraiser",
            icon: "ThisMonthAppraisers",
            value: 0,
        },
        activeUsers: {
            label: "Active Appraisers",
            icon: "ActiveAppraisers",
            value: 0,
        }
    },
    admin: {
        totalOrders: {
            label: "Total Orders",
            icon: "TotalOrder",
            value: 0,
        },
        totalRevisions: {
            label: "Total Revisions",
            icon: "TotalReviewer",
            value: 0,
        },
        totalAppraiser: {
            label: "Total Apraisers",
            icon: "TotalAppraisers",
            value: 0,
        },
        totalInspector: {
            label: "Total Inspectors",
            icon: "TotalInspector",
            value: 0,
        },
        totalClients: {
            label: "Total Clients",
            icon: "ThisMonthUser",
            value: 0,
        },
    },
}

export const unApprovedUsersList = [
    {
        field: 'firstName',
        headerName: 'Name',
        renderCell: renderCellContent,
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 250
    },
    {
        field: 'cellPhone',
        headerName: 'Phone Number',
        type: 'number',
    },
    {
        field: 'stateLicenses',
        headerName: 'License Number',
        renderCell: renderLicenseCellContent
    },
    {
        field: 'address',
        headerName: 'Address',
    }
];

export const clientHeader = [
    {
        label: "All Properties",
        icon: "PropertyNotSelected",
        value: "Property",
        selectedIcon: "Property",
    },
    {
        label: "All Orders",
        icon: "Orderss",
        value: "Orders",
        selectedIcon: "OrderSelected",
    }
];

export const appraiserHeader = [
    {
        label: "Assigned",
        icon: "Assigned",
        value: "Assigned",
        selectedIcon: "AssignedSelected",
    },
    {
        label: "Completed",
        icon: "Completed",
        value: "Completed",
        selectedIcon: "CompletedSelected",
    },
    {
        label: "Revision",
        icon: "Revision",
        value: "Revision",
        selectedIcon: "RevisionSelected",
    },
    {
        label: "Hold/Cancel",
        icon: "Hold",
        value: "Hold",
        selectedIcon: "HoldSelected",
    }
];

export const inspectorHeader = [
    {
        label: "Assigned",
        icon: "Assigned",
        value: "Assigned",
        selectedIcon: "AssignedSelected",
    },
    {
        label: "Scheduled",
        icon: "Schedule",
        value: "Scheduled",
        selectedIcon: "ScheduleSelected",
    },
    {
        label: "Completed",
        icon: "Completed",
        value: "Completed",
        selectedIcon: "CompletedSelected",
    },
    {
        label: "Revision",
        icon: "Revision",
        value: "Revision",
        selectedIcon: "RevisionSelected",
    },
    {
        label: "Hold/Cancel",
        icon: "Hold",
        value: "Hold",
        selectedIcon: "HoldSelected",
    }
];

export const reviewerHeader = [
    {
        label: "Assigned",
        icon: "Assigned",
        value: "Assigned",
        selectedIcon: "AssignedSelected",
    },
    {
        label: "Incomplete",
        icon: "Schedule",
        value: "Incomplete",
        selectedIcon: "ScheduleSelected",
    },
    {
        label: "Completed",
        icon: "Completed",
        value: "Completed",
        selectedIcon: "CompletedSelected",
    },
    {
        label: "Approved",
        icon: "Revision",
        value: "Approved",
        selectedIcon: "RevisionSelected",
    },
    {
        label: "Pending Revision",
        icon: "Pending",
        value: "Pending Revision",
        selectedIcon: "PendingSelected",
    },
    {
        label: "Hold/Cancel",
        icon: "Hold",
        value: "Hold",
        selectedIcon: "HoldSelected",
    }
];
