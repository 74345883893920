import React, { useEffect, useRef, useState } from "react";
import Template from "../../Template";
import Ellipse8 from "../../Assets/Images/Ellipse8.svg";
import { Box, CircularProgress } from '@mui/material';
import Text from "../../Components/Core/Text";
import Input from "../../Components/Core/Input";
import { getSupportTokenList, getSupportTokenMessageByTokenId, sendSupportTokenMessageByTokenId, getSupportTokenDetail } from "../../Actions/message";
import { chatHeaders } from '../../Helpers/DataHelper';
import Icon from "../../Helpers/Icon";
import {
    ChatPlus,
    MenuCircleVertical,
    PlusThin,
    Search,
    Send,
} from "react-huge-icons/outline";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import AlertBox from "../../Components/Alert";
import TicketInfo from "../../Components/TicketInfo";
import Modal from '@mui/material/Modal';

function Support() {
    const dispatch = useDispatch();
    const [pageType, setPageType] = useState("all");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [selected, setSelected] = useState(1);
    const [showMenu, setShowMenu] = useState(false);
    const [supportTokenLst, setSupportTokenList] = useState([]);
    const [alert, setAlert] = useState({ show: false, type: "", message: "" });
    const [newMessage, setNewMessage] = useState("");
    const auth = useSelector((state) => state.auth);
    const [file, setFile] = useState(null);
    const [messageList, setMessageList] = useState([]);
    const [ticketInfo, setTicketInfo] = useState(null);
    const [ticketDetail, setTicketDetail] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredList, setFilteredList] = useState([]);

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const boxRef = useRef(null);

    useEffect(() => {
        getChatListData(pageType)
    }, []);

    useEffect(() => {
        const scrollToBottom = () => {
            if (boxRef.current) {
                boxRef.current.scrollTop = boxRef.current.scrollHeight;
            }
        };

        scrollToBottom();
        setSelected(selected);
        if (selected) {
            getMessageData(selected);
        }
    }, [messageList?.length]);

    useEffect(() => {
        if (selected) {
            const intervalId = setInterval(() => {
                getMessageData(selected);
            }, 2000);

            return () => clearInterval(intervalId);
        }
    }, [selected]);

    const getChatListData = async (type) => {
        try {
            setPageType(type);
            setIsLoading(true);
            const result = await dispatch(getSupportTokenList({ type }));
            setSupportTokenList(result.data || []);
            setFilteredList(result.data || []);
            if (result.data && result.data.length > 0) {
                const firstSupportTokenId = result.data[0]._id;
                setSelected(firstSupportTokenId);
                getMessageData(firstSupportTokenId, true);
                setTicketInfo(result.data[0]);
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error occurred while fetching chat list data:", error);
            setIsLoading(false);
        }
    };

    const getMessageData = async (id, isLoading) => {
        try {
            if (id === 1) {
                return;
            }
            if (isLoading) {
                setMessageList([]);
                setIsLoading1(true);
            }
            const result = await dispatch(getSupportTokenMessageByTokenId(id));
            if (result.data && result.data.length > 0) {
                // Update messageList with unique messages
                setMessageList(prevMessages => {
                    const uniqueMessages = result.data.filter(message => (
                        !prevMessages.some(prevMessage => prevMessage._id === message._id)
                    ));
                    return [...prevMessages, ...uniqueMessages];
                });
                setIsLoading1(false);
            }
        } catch (error) {
            console.error("Error occurred while fetching message data:", error);
            setAlert({ show: true, type: 'error', message: error?.response?.data?.error || "An error occurred." });
        } finally {
            setIsLoading1(false);
        }
    };

    const handleSelectSupportToken = (item) => {
        setSelected(item._id);
        setTicketInfo(item);
        // dispatch(getSupportTokenMessageByTokenId(id));
        getMessageData(item._id, true);
    };

    const ticketInfoModel = async () => {
        try {
            setOpen(true)
            const result = await dispatch(getSupportTokenDetail(selected));
            setTicketDetail(result.data || null);
        } catch (error) {
            console.error("Error occurred while fetching chat list data:", error);
            setIsLoading(false);
            setAlert({ show: true, type: 'error', message: error?.response?.data?.error || "An error occurred." });
        }
    };


    const sendMessage = async () => {
        if (!newMessage.trim()) return;
        const data = new FormData();
        data.append("message", newMessage);
        data.append("file", file);
        setNewMessage("");
        try {
            await dispatch(sendSupportTokenMessageByTokenId(selected, data));
            dispatch(getSupportTokenMessageByTokenId(selected));
        } catch (error) {
            console.error("Error occurred while sending message:", error);
            setAlert({ show: true, type: 'error', message: error?.response?.data?.error || "An error occurred." });
        }
    };

    const filterList = () => {
        const filtered = supportTokenLst.filter(item =>
            item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.ticketNumber.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredList(filtered);
    };

    // Event handler for input change
    const handleInputChange = event => {
        setSearchQuery(event.target.value);
        if (!event.target.value) {
            setFilteredList(supportTokenLst);
        }
    };

    // Event handler for Enter key press
    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            filterList();
        }
    };

    return (
        <Template
            showHeader
            showSidebar
            headerProps={{
                showSearch: true,
                showNotification: true,
                showProfile: true
            }}
            sidebarProps={{
                page: "support-token"
            }}
    
           
            containerStyle={{
                backgroundImage: `url(${Ellipse8})`,
            }}
        >

            <AlertBox open={alert.show} type={alert.type} message={alert.message} onChange={(value) => { setAlert({ show: value, type: '', message: "" }); }} />


            <Box
                sx={{
                    border: "1px solid #FFFFFF",
                    boxShadow: "4px 15px 50px #00000029;",
                    borderRadius: "24px",
                    backgroundColor: "#FFFFFF",
                    opacity: "1",
                    padding: "1.5em",
                    backdropFilter: "blur(50px)",
                    WebkitBackdropFilter: "blur(50px)",
                    width: "73%",
                    margin: "auto",
                    marginLeft: "20em"
                }}
            >
                <Box style={{ display: "flex", flexDirection: "row" }}>
                    <Box sx={{ mr: "auto" }}>
                        <Text variant="h2" style={{ font: 'normal normal 600 19px/31px Poppins' }}>All Support Ticket</Text>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: "auto",
                        marginTop: "6px",
                        marginBottom: "-20px",
                    }}
                >
                    {chatHeaders.map((item, index) => (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                background:
                                    pageType === item.value
                                        ? "#FFFFFF 0% 0% no-repeat padding-box"
                                        : "#F2F2F2 0% 0% no-repeat padding-box",
                                borderRadius: "20px 20px 0px 0px",
                                height: "59px",
                                width: "189px",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                color: pageType === item.value ? "#2D81FF" : "#000000",
                                margin: "0px 4px",
                                paddingTop: "2px",
                                position: "relative",
                                boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px;"
                            }}
                        >
                            <Box
                                onClick={() => getChatListData(item.value)}
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    height: "100%",
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Box
                                    sx={{
                                        color:
                                            pageType === item.value ? "#2593D6 !important" : "#000000",
                                    }}
                                >
                                    <Icon
                                        name={item.value === pageType ? item.selectedIcon : item.icon}
                                        style={{
                                            width: "17px",
                                            height: "19px",
                                            marginTop: "6px",
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        font: "normal normal normal 13px Poppins",
                                        color: pageType === item.value ? "#2593D6" : "#000000",
                                        marginLeft: "10px",
                                    }}
                                >
                                    {item.label}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>

                <Box
                    sx={{
                        boxShadow: "15px 15px 50px #00000029",
                        borderRadius: "24px",
                        background: "#F8F8F8 0% 0% no-repeat padding-box",
                        display: "flex",
                        flexDirection: "row",
                        opacity: 1,
                        padding: "1.5em",
                        backdropFilter: "blur(50px)",
                        WebkitBackdropFilter: "blur(50px)",
                        margin: "auto",
                        height: "100vh",
                        mt: "1em",
                    }}
                >
                    {isLoading ? (
                        <CircularProgress
                            size={40}
                            sx={{
                                color: "#2C80FF",
                                display: "flex",
                                margin: "Auto",
                                marginTop: "15px"
                            }}
                        />
                    ) : (
                        <>
                            <Box
                                sx={{
                                    mr: "auto",
                                    width: "30%",
                                    borderRight: "1.6px solid #ccc",
                                    paddingRight: "1.6em",
                                    mr: "1em",
                                }}
                            >
                                <Input
                                    placeholder="Search"
                                    isTransparent
                                    style={{
                                        width: "100%",
                                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                                        borderRadius: "17px",
                                        height: 56,
                                    }}
                                    value={searchQuery}
                                    onChange={handleInputChange}
                                    onKeyPress={handleKeyPress}
                                    icon={<Search style={{ color: "#A8A8A8", marginTop: -12 }} />}
                                />
                                <Box
                                    sx={{
                                        height: "80vh",
                                        overflowY: "scroll",
                                        "&::-webkit-scrollbar": {
                                            width: "0px",
                                        }
                                    }}
                                >
                                    {filteredList.map((item) => (
                                        <Box
                                            style={{
                                                background: selected === item._id ? "#fff" : "transparent",
                                                borderRadius: selected === item._id ? 20 : 0,
                                                padding: "1em",
                                                width: "90%",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                marginTop: "1em",
                                                cursor: "pointer",
                                                borderBottom:
                                                    selected === item._id ||
                                                        filteredList[filteredList.length - 1]._id === item._id
                                                        ? "none"
                                                        : "1px solid #ccc",
                                            }}
                                            onClick={() => handleSelectSupportToken(item, true)}
                                        >
                                            <Box style={{ display: "flex", flexDirection: "row" }}>
                                                <Text
                                                    style={{
                                                        color: "#011502",
                                                        font: "normal normal 600 13px/25px Poppins",
                                                        fontSize: "13px",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    {item.title}
                                                </Text>
                                                <Text
                                                    style={{
                                                        color: item.isOpen ? "#35C736" : "#EF2B2C",
                                                        marginLeft: "auto",
                                                        font: "normal normal normal 11px/16px Poppins",
                                                        fontSize: "11px",
                                                    }}
                                                >
                                                    {item.isOpen ? "Open" : "Closed"}
                                                </Text>
                                            </Box>
                                            <Box style={{ display: "flex", flexDirection: "row" }}>
                                                <Text
                                                    style={{
                                                        color: "#818181",
                                                        font: "normal normal normal 12px Poppins",
                                                        fontSize: "11px",
                                                        marginTop: "-.2em",
                                                    }}
                                                >
                                                    {item.latestMessage?.message}
                                                </Text>
                                                <Text
                                                    style={{
                                                        color: "#8D8D8D",
                                                        marginLeft: "auto",
                                                        font: "normal normal normal 10px Poppins",
                                                        fontSize: "10px",
                                                    }}
                                                >
                                                    {moment(item.latestMessage?.createdAt).fromNow()}
                                                </Text>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            <Box sx={{ width: "66%", paddingX: "1em" }}>
                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        position: "relative",
                                    }}
                                >
                                    <div style={{
                                        display: "grid",
                                        position: "relative",
                                    }}>
                                        <Text
                                            style={{
                                                color: "#011502",
                                                font: "normal normal 600 13px/25px Poppins",
                                                fontSize: "17px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            {ticketInfo ? ticketInfo.title : ''}
                                        </Text>
                                        <Text
                                            style={{
                                                color: "#011502",
                                                font: "normal normal normal 11px/16px Poppins;",
                                                fontSize: "11px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            Ticket No: #{ticketInfo ? ticketInfo.ticketNumber : ''}
                                        </Text>
                                    </div>
                                    <MenuCircleVertical
                                        fontSize={32}
                                        onClick={() => setShowMenu(!showMenu)}
                                        color="#d6d848"
                                        style={{ marginLeft: "auto", cursor: "pointer" }}
                                    />
                                    {showMenu && (
                                        <Box onClick={ticketInfoModel}
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                right: 0,
                                                boxShadow: "0px 10px 20px #0000000D",
                                                borderRadius: "12px !important",
                                                padding: "1em",
                                                zIndex: 1,
                                                backgroundColor: "#fff",
                                                mt: "2em",
                                            }}
                                        >
                                            {["Ticket Info"].map(
                                                (item, index) => (
                                                    <Box
                                                        sx={{
                                                            cursor: "pointer",
                                                            mb: index === 2 ? 0 : "1em",
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                color: item === "Report" ? "#FF0000" : "#4C5056",
                                                                font: "normal normal normal 14px/21px Poppins",
                                                                fontSize: "13.5px",
                                                            }}
                                                        >
                                                            {item}
                                                        </Text>
                                                    </Box>
                                                )
                                            )}
                                        </Box>
                                    )}

                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box>
                                            {ticketDetail && (
                                                <TicketInfo data={ticketDetail} handleClose={handleClose} />
                                            )}
                                        </Box>
                                    </Modal>
                                </Box>


                                <Box
                                    sx={{
                                        height: "80vh",
                                        overflowY: "scroll",
                                        pr: "1em",
                                        "&::-webkit-scrollbar": {
                                            width: "4px",
                                        },
                                        "&::-webkit-scrollbar-track": {
                                            backgroundColor: "#F0F0F0",
                                        },
                                        "&::-webkit-scrollbar-thumb": {
                                            backgroundColor: "#2C80FF",
                                            borderRadius: "4px",
                                        },
                                        scrollbarColor: "#2C80FF #F0F0F0",
                                        scrollbarWidth: "thin",
                                    }}
                                    ref={boxRef}
                                >
                                    {isLoading1 ? (
                                        <CircularProgress
                                            size={40}
                                            sx={{
                                                color: "#2C80FF",
                                                display: "flex",
                                                margin: "Auto",
                                                marginTop: "15px"
                                            }}
                                        />
                                    ) : (
                                        <>

                                            {messageList && messageList.length && messageList.map((item) => (
                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        marginTop: "1em",
                                                    }}
                                                >
                                                    <Box
                                                        style={{
                                                            background: item.from_id === auth?.user?._id ? "#2593D6" : "#fff",
                                                            borderRadius: 24,
                                                            padding: "1em",
                                                            width: "auto",
                                                            marginLeft: item.from_id === auth?.user?._id ? "auto" : 0,
                                                            marginRight: item.from_id === auth?.user?._id ? 0 : "auto",
                                                            marginTop: "1em",
                                                            borderRadius:
                                                                item.from_id === auth?.user?._id
                                                                    ? "24px 24px 0px 24px"
                                                                    : "24px 24px 24px 0px",
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                color: item.from_id === auth?.user?._id ? "#fff" : "#818181",
                                                                font: "normal normal normal 14px Poppins",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {item.message}
                                                        </Text>
                                                    </Box>
                                                    <Text
                                                        style={{
                                                            color: "#818181",
                                                            font: "normal normal normal 14px Poppins",
                                                            fontSize: "10.5px",
                                                            marginLeft: item.from_id === auth?.user?._id ? "auto" : 0,
                                                        }}
                                                    >
                                                        {moment(item.createdAt).fromNow()}
                                                    </Text>
                                                </Box>
                                            ))}
                                        </>
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        background: "#2593D6",
                                        display: "flex",
                                        flexDirection: "row",
                                        padding: ".7em",
                                        mt: "1em",
                                        height: 44,
                                        borderRadius: 24,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            background: "#fff",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "50%",
                                            width: 46,
                                            height: 42,
                                            cursor: "pointer",
                                            mt: "auto",
                                            mb: "auto",
                                        }}
                                    >
                                        <PlusThin fontSize={32} />
                                    </Box>
                                    <Box
                                        sx={{
                                            ml: ".7em",
                                            width: "100%",
                                            background: "#fff",
                                            borderRadius: "24px",
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <Input
                                            value={newMessage}
                                            onChange={(e) => setNewMessage(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    sendMessage(); // Call sendMessage function when Enter key is pressed
                                                }
                                            }}
                                            placeholder="Type a message"
                                            style={{
                                                width: "95%",
                                                background: "#fff",
                                                borderRadius: 18,
                                                paddingLeft: ".5em",
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                background: "#00476a",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "50%",
                                                width: 32,
                                                height: 32,
                                                cursor: "pointer",
                                                mt: ".4em",
                                                mr: ".5em",
                                            }}
                                        >
                                            <Send onClick={sendMessage}
                                                fontSize={18}
                                                color="#fff"
                                                style={{ transform: "rotate(375deg)", marginTop: ".1em" }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    )}

                </Box>
            </Box>
        </Template>
    );
}

export default Support;
