import { Box } from "@mui/material";
import React, { useState } from "react";
import "./index.css";
import Icon from "../Helpers/Icon";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const MorePopUp = ({ handleClose, rowData, suspendAccount, deleteAccount }) => {
  const [showMenu, setShowMenu] = useState(false);

  console.log(rowData);

  return (
    <Box>
      <Icon
        onClick={(event) => {
          setShowMenu(!showMenu);
          event.stopPropagation();
        }}
        name="More"
        style={{
          width: "20px",
          height: "5px",
          // marginRight: "1em",
          cursor: "pointer",
          // position: "relative",
          padding: "2rem",
        }}
      />
      {showMenu && (
        <Box>
          <Box
            sx={{
              boxShadow: "0px 10px 20px #0000000D",
              borderRadius: "12px",
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              height: "fit-content",
              width: "131px",
              zIndex: 1,
              ml: "-89px",
              mt: "2%",
              padding: "1em",
              display: "grid",
              position: "absolute",
            }}
          >
            <span
              onClick={(event) => {
                suspendAccount(rowData, event);
                event.stopPropagation();
              }}
              style={{
                font: " normal normal 600 13px/13px Poppins",
                color: "#000000",
                marginBottom: 10,
                cursor: "pointer",
              }}
            >
              {rowData.status === 3 ? "Activate" : "Suspend"} Account
            </span>

            <span
              onClick={(event) => {
                deleteAccount(rowData._id);
                event.stopPropagation();
              }}
              style={{
                font: " normal normal 600 13px/13px Poppins",
                color: "#EF2B2C",
                marginBottom: 10,
                cursor: "pointer",
              }}
            >
              Delete Account
            </span>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MorePopUp;
