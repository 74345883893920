import axios from "../Utils/axios";
import { adminConstants } from "./constants";
import { apiUrls } from "../Helpers/Constants";

// Assuming your getAdmins action creator is defined like this
export const getAdmins = (params) => {
    return async (dispatch) => {
        dispatch({ type: adminConstants.ADMINS_REQUEST });

        try {
            const res = await axios.get(apiUrls.admins, { params });

            if (res.status === 200) {
                dispatch({
                    type: adminConstants.ADMINS_SUCCESS,
                    payload: {
                        admin: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: adminConstants.ADMINS_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch admins'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching admins:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const addAdmins = (data) => {
    return async (dispatch) => {
        dispatch({ type: adminConstants.ADD_ADMIN_REQUEST });

        try {
            const res = await axios.post(apiUrls.addAdmins, data);

            if (res.status === 200) {
                dispatch({
                    type: adminConstants.ADD_ADMIN_SUCCESS,
                    payload: {
                        admin: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: adminConstants.ADD_ADMIN_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to add admins'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error adding admin:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getAdminDetail = (id) => {
    return async (dispatch) => {
        dispatch({ type: adminConstants.ADMIN_DETAIL_REQUEST });

        try {
            const res = await axios.get(`${apiUrls.getAdminDetail}${id}`);

            if (res.status === 200) {
                dispatch({
                    type: adminConstants.ADMIN_DETAIL_SUCCESS,
                    payload: {
                        users: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: adminConstants.ADMIN_DETAIL_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch users'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const updateAdminDetail = (id, data) => {
    return async (dispatch) => {
        dispatch({ type: adminConstants.ADMIN_DETAIL_REQUEST });

        try {
            const res = await axios.post(`${apiUrls.updateAdminDetails}${id}`,data);

            if (res.status === 200) {
                dispatch({
                    type: adminConstants.ADMIN_DETAIL_SUCCESS,
                    payload: {
                        users: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: adminConstants.ADMIN_DETAIL_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch users'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

